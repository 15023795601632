.FormArray h3 {
  display: flex;
  justify-content: space-between;
}

.FormArrayRow {
  display: grid;
  grid-column-gap: 16px;
  align-items: center;

  /** TODO need optimized solution */
}

.FormArrayRow + .FormArrayRow {
  margin-top: 20px;
}

.FormArrayRow[data-columns-length="1"] {
  grid-template-columns: 1fr 36px;
}

.FormArrayRow[data-columns-length="2"] {
  grid-template-columns: 1fr 1fr 36px;
}

.FormArrayRow:last-child button {
  grid-column-start: -1;
}

