.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  color: rgb(255 255 255);
  height: 32px;
  min-width: 32px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  background: linear-gradient(rgb(2 158 71) 0%, rgb(1 128 58) 100%);
}

.Button:hover {
  background: linear-gradient(rgb(1 192 86) 0%, rgb(1 128 58) 100%);
}

.Button:active {
  background-color: rgb(1 139 63);
  box-shadow: none;
  color: rgb(204 230 216);
}

.Button::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 4px;
  width: 0;
  border-bottom: 13px solid rgb(0 101 45);
  border-left: 11px solid rgb(8 139 65);
}
