.DeleteButton {
  color: #818c99;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.DeleteButton:hover {
  color: #4e5d60;
}

.DeleteButton > svg {
  width: 24px;
  height: 24px;
}
